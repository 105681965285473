import React, { useContext, useEffect } from "react";
import PageHeader from "../components/PageHeader";
import { ThemeContext } from "../layout/Layout";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import InnerHeader from "../components/InnerHeader";
import Marquee from "../components/Marquee";

const ItemDetail = ({ key, title, counter, detail, theme }) => {
  return (
    <div key={key} className="flex lg:h-fourth items-center justify-between lg:my-16 my-8 flex-col">
      <div className="flex flex-col lg:flex-row lg:h-fourth lg:p-10 w-full">
        <div className="lg:w-2/4 w-full flex flex-col items-start justify-between">
          <p className="text-2xl helv font-semibold">{title}</p>
          <p className="italic text-primary-100 font-light capitalize">{counter}</p>
        </div>
        <div className="lg:w-2/4  w-full mb-4 lg:mb-0 flex flex-col justify-between text-lg lg:text-2xl">{detail}</div>
      </div>
      <hr className={`w-full h-0 mx-8 border-t mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
    </div>
  );
};

const pageDetails = {
  head: {
    upper: "Every Project Needs a",
    lower: "Process",
  },
  bottom: {
    normal: "DATA-DRIVEN APPROACH",
    italic: "FOR BUSINESS SUCCESS",
  },
};

const discovery = [
  {
    title: "DISCOVERY CALL",
    counter: "DISCOVERY / 01",
    detail: `The discovery call is an introductory meeting that helps us get to know you and understand your business, business goals, objectives, budget, and timeline. During this call, we will scope your project to see how we can help.`,
  },
  {
    title: "SCOPE OF WORK",
    counter: "DISCOVERY / 02",
    detail: `After our call, we will provide you with a detailed scope of work, timeline, and budget proposal. A further discovery call may be scheduled with you if we have any follow-up questions.The scope of work and proposal will be available for both of us to review, edit, and approve on Google Docs.`,
  },
  {
    title: "CONTRACT REVIEW",
    counter: "DISCOVERY / 03",
    detail: `Before designing, it is important for us to understand the content and the story your website needs to tell to its visitors. Content allows us to plan strategic designs, illustrations, animations, and advanced interactions for your website. Content architecture is the foundation of all good UI/UX designs.`,
  },
];

const strategy = [
  {
    title: "COMMUNICATION",
    counter: "STRATEGY / 01",
    detail: `
    After the service agreement is signed, we will assign you a dedicated project manager who will be the point of contact for effective communications between you and our team.
    They will organize communication channels and keep track of the work process to ensure all work is completed on schedule according to the scope of work.`,
  },
  {
    title: "PRE-BUILD DISCUSSIONS",
    counter: "STRATEGY / 02",
    detail: `A Kick-Off call will be scheduled between you and your project manager to review all the details of the project before moving forward. Depending on the scope of work, we will be discussing and analyzing many topics including, but not limited to, website goals and objectives, website concerns, website aesthetics, user flows, site map, current brand manuals, brand assets, etc. After our call, we will provide you with a detailed scope of work, timeline, and budget proposal. A further discovery call may be scheduled with you if we have any follow-up questions.The scope of work and proposal will be available for both of us to review, edit, and approve on Google Docs.`,
  },
  {
    title: "CONTENT ARCHITECTURE",
    counter: "STRATEGY / 03",
    detail: `Once both parties approve and sign off on the scope of work, timeline, and budget proposal, we will send over a service agreement for you to review and e-sign.At this time, we will also sign NDAs for your project if we discussed them beforehand.`,
  },
];

gsap.registerPlugin(ScrollTrigger);

export default function Approach() {
  const theme = useContext(ThemeContext);

  useEffect(() => {
    const tl = gsap.timeline().add(".upcounter", { ScrollTrigger: { trigger: ".comtrig", start: "top top", end: "bottom bottom", markers: true }, x: "-100%" });
  }, []);

  return (
    <>
      <div className="w-screen relative z-20 lg:min-h-screen flex flex-col items-start justify-center">
        <PageHeader title="Approach" />
        <InnerHeader head={pageDetails.head} bottom={pageDetails.bottom} />
        <div className="w-screen">
          <div className={`container sticky top-0 mx-auto flex flex-col ${theme === "dark" ? "bg-black" : "bg-white"}`}>
            <div className="flex items-center px-8 text-lg lg:text-2xl py-4 lg:py-8 justify-between">
              <div className="flex items-center justify-center">
                <div className="w-2 h-2 bg-primary-100 rounded-full"></div> <p className="ml-3 italic radio text-primary-100 capitalize "> Discover Phase</p>
              </div>
              <p className="helv upcounter">01</p>
              <div className="flex items-center justify-center">
                <p className="helv capitalize ">Request A Quote</p>
                {/* <div className="w-10 h-10 rounded-full ml-4 flex  items-center justify-center"></div> */}
              </div>
            </div>
            <hr className={`w-full h-0 border-t mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
          </div>
          <div className="container px-8 mx-auto grid lg:grid-cols-2 grid-cols-1">
            <div className="flex col-span-1 md:sticky h-1 pt-20 top-64 ">
              <div className="flex w-full">
                <div className="w-2/12 p-8 hidden lg:block text-5xl helv">01</div>
                <div className="lg:w-10/12 w-full lg:pr-8 flex flex-col">
                  <p className=" lg:text-6xl text-3xl">
                    <span className="italic text-primary-100 font-light capitalize">Learning is our Approach</span>
                    <span className="radio text-black font-semibold"> FOR DISCOVERY</span>
                  </p>
                  <p className="py-16 hidden lg:block pr-20 text-2xl">Let's get to know each other. Allow us to understand your current and future business goals, visions, and objectives to help you reach your goals.</p>
                </div>
              </div>
            </div>
            <div className="pt-20 h-3.5vh">
              {discovery.map((item, idx) => {
                return <ItemDetail key={idx} title={item.title} theme={theme} counter={item.counter} detail={item.detail} />;
              })}
              <div className=" lg:h-fourth h-third "></div>
              {strategy.map((item, idx) => {
                return <ItemDetail key={idx} title={item.title} theme={theme} counter={item.counter} detail={item.detail} />;
              })}
            </div>
          </div>
        </div>
        <div className="w-screen hidden h-screen lg:flex"></div>
        <Marquee cont="SERVICES" />
      </div>
    </>
  );
}
